import TableAttivita from "../elements/attivita/tableAttivita";
import Dashboard_Loader from "../navigation/dashboard_loader";
import { useState, useEffect } from "react";
import axios from 'axios';

function AttivitaArchivio({ navOpened }) {

    const [showLoader, setShowLoader] = useState(false);
    const [data, setData] = useState([]);

    const fetchAll = async () => {
        setShowLoader(true);
        axios.get('https://www.tonight-torino.com/php/attivita.php')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    const filteredData = response.data.filter(item => item.Stato === "Concluso");
                    const sortedData = filteredData.sort((a, b) => {
                        return new Date(b.Creazione) - new Date(a.Creazione);
                    });

                    setData(sortedData);
                } else {
                    console.log(response);
                    console.error('Errore: la risposta non è un array', response.data);
                }
            })
            .catch((error) => {
                console.error('Errore nella richiesta AJAX: ', error);
            })
            .finally(() => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 800);
            });
    };


    useEffect(() => {
        fetchAll();
    }, []);

    const Choices1 = [
        {
            icon: '',
            description: 'Sabelt'
        },
        {
            icon: '',
            description: 'Lamborghini'
        },
        {
            icon: '',
            description: 'Proma Grugliasco'
        },
        {
            icon: '',
            description: 'Aras'
        },
        {
            icon: '',
            description: 'Lear Melfi'
        }
    ];

    return (
        <>
            <div className={`Ticket_InCorso_MainDiv ${navOpened ? "padding-leftTickets" : ""}`}>
                <TableAttivita navOpened={navOpened} requireDataHandler={() => fetchAll()} data={data} Choices1Data={Choices1} Choices1Text="Cliente" />
            </div>
            {showLoader && <Dashboard_Loader />}
        </>
    );
};

export default AttivitaArchivio;
import { useState, useEffect, useRef } from "react";
import { HiChevronDown } from "react-icons/hi";

function Selecter({ Status, Choices, setSelection, AlreadySetted }) {

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    const [localSelection, setLocalSelection] = useState('');
    const [realStatus, setRealStatus] = useState('');
    const selecterRef = useRef(null); 

    useEffect(() => {
        setRealStatus(Status);
    }, [Status]);

    const setRealSelected = (last_choice, new_choice) => {
        if (last_choice === new_choice) {
            setSelected(!selected);
            setRealStatus(Status);
            setSelection("");
            setLocalSelection("");
        }
        else {
            setSelected(true);
            setSelection(new_choice);
            setRealStatus(new_choice);
            setLocalSelection(new_choice);
        }
        setOpen(false);
    }

    
    useEffect(() => {
        if (AlreadySetted) {
            // Verifica se AlreadySetted è un numero
            if (!isNaN(AlreadySetted)) {
                // Converti il valore numerico in stringa corrispondente
                let statusText;
                switch (parseInt(AlreadySetted)) {
                    case 0:
                        statusText = "Normale";
                        break;
                    case 1:
                        statusText = "Importante";
                        break;
                    case 2:
                        statusText = "Urgente";
                        break;
                    default:
                        statusText = "Sconosciuto"; // Opzionale, in caso il valore non corrisponda
                }
                setLocalSelection(statusText);
                setRealStatus(statusText);
            } else {
                // Se AlreadySetted non è un numero, mantieni il valore com'è
                setLocalSelection(AlreadySetted);
                setRealStatus(AlreadySetted);
            }
            setSelected(true);
            console.log(AlreadySetted);
        }
    }, [AlreadySetted]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selecterRef.current && !selecterRef.current.contains(event.target)) {
                setOpen(false); 
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selecterRef]);


    return (
        <>
            <div className="Selecter_MainDiv_Upper" ref={selecterRef}>
                <div className={`Selecter_MainDiv ${selected ? "selected" : ""}`} onClick={() => setOpen(!open)}>
                    {selected && <div className="Selecter_Number"></div>}
                    {Status && <div className="Selecter_Text">
                        {realStatus}
                    </div>}
                    <div>
                        <HiChevronDown />
                    </div>
                </div>
                <div className={`Selecter_MainDiv_Choices ${open ? "open" : ""}`}>
                    {Array.isArray(Choices) && Choices.map((choice, index) => (
                        <div key={index} className="selecter_choice" onClick={() => setRealSelected(localSelection, choice.description)}>
                            {choice.icon} {choice.description}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Selecter;

import ItodoImage from '../../../elements/itodo-img';

function InfoViewer({ handleClose, data }) {

    return (
        <>
            <div className='divApertoTicket'>
                <div className="InfoModalMainDiv">
                    <div className="info_viewer_maindiv">
                        <span onClick={handleClose} className='chiudiDivxTicket'>
                            &times;
                        </span>
                        <div className="info_viewer_accountspace">
                            <div className="info_viewer_accountspace_circle">
                                <ItodoImage src={data.Autore === "sabelt@auth.com" ? "images/clienti/sabelt.png" : "images/logohead.png"}></ItodoImage>
                            </div>
                            <div className='info_viewer_accountspace_desc'>
                                <div className="info_viewer_accountspace_title">
                                    {data.Nome}
                                </div>
                                <div className="info_viewer_accountspace_title_low">
                                    {data.Email}
                                </div>
                                <div className="info_viewer_accountspace_title_low">
                                    {data.Cell}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default InfoViewer;
import axios from 'axios'

function TicketChatMessage({ canDelete, isAdmin, isScanteq, Name, Date, Message, MessageID, onRefresh }) {

    const handleDeleteMessage = async () => {

        const isConfirmed = window.confirm("Sei sicuro di voler eliminare questo commento?");
        if (!isConfirmed) return;

        try {
            const response = await axios.delete('https://www.tonight-torino.com/php/comments_ticket.php', {
                data: { commentId: MessageID }
            });
            console.log(response.data); 
            onRefresh();
        } catch (error) {
            console.error("Errore nell'eliminazione del commento:", error);
        }
    };

    return (
        <div className={`ticket_chatmessage_${isScanteq ? "dark" : "light"}`}>
            {isAdmin && canDelete && <div onClick={handleDeleteMessage} className="ticket_chatmessage_delete">X</div>}
            <div className={`ticket_message_header_${isScanteq ? "dark" : "light"}`}>
                <div className="ticket_message_avatar">
                    {Name.charAt(0).toUpperCase()}
                </div>
                <div className="ticket_message_sender">{Name}</div>
                <div className="ticket_message_date">{Date}</div>
            </div>
            <div className="ticket_message_body" dangerouslySetInnerHTML={{ __html: Message.replace(/\n/g, '<br>') }} />
        </div >
    );
}

export default TicketChatMessage;

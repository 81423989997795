import { useState } from 'react';
import { HiOutlineArrowLeft } from "react-icons/hi";

function NoPermissionModal({ callBackCloseFunction,Title,Desc }) {

    return (
        <>
            <div className='NoPermissionModal_MainDiv'>
                <div className='NoPermissionModal_MainDiv_Closer' onClick={callBackCloseFunction}>
                    <HiOutlineArrowLeft/>
                </div>

                <div class="wrapperNoPermissionModal">
                    <div class="circleNoPermissionModal"></div>
                    <div class="circleNoPermissionModal"></div>
                    <div class="circleNoPermissionModal"></div>
                    <div class="shadowNoPermissionModal"></div>
                    <div class="shadowNoPermissionModal"></div>
                    <div class="shadowNoPermissionModal"></div>
                </div>
                <div className='NoPermissionModal_MainDiv_Title'>
                    {Title}
                </div>
                <div className='NoPermissionModal_MainDiv_Desc'>
                    {Desc}
                </div>
            </div>
        </>
    );
}

export default NoPermissionModal;

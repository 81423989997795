function TicketLoading() {

    return (
        <>
            <div class="loader_ticketmessage">
                <div class="wrapper_ticketmessage">
                    <div class="circle_ticketmessage"></div>
                    <div class="line-1_ticketmessage"></div>
                    <div class="line-2_ticketmessage"></div>
                    <div class="line-3_ticketmessage"></div>
                    <div class="line-4_ticketmessage"></div>
                </div>
            </div>
            <div class="loader_ticketmessage">
                <div class="wrapper_ticketmessage">
                    <div class="circle_ticketmessage"></div>
                    <div class="line-1_ticketmessage"></div>
                    <div class="line-2_ticketmessage"></div>
                    <div class="line-3_ticketmessage"></div>
                    <div class="line-4_ticketmessage"></div>
                </div>
            </div>
        </>
    );

};

export default TicketLoading;
function Dashboard_Loader() {

    return (
        <>
        <div className="loader_dashboard_upper">
            <div class="loader_dashboard">
                <div class="loader__bar_dashboard"></div>
                <div class="loader__bar_dashboard"></div>
                <div class="loader__bar_dashboard"></div>
                <div class="loader__bar_dashboard"></div>
                <div class="loader__bar_dashboard"></div>
                <div class="loader__ball_dashboard"></div>
            </div>
        </div>
        </>
    );

};

export default Dashboard_Loader;
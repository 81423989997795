function Overview_Card({Icon,Number,Text}) {
    return (
        <>
            <div className="Overview_Card">
                
                <div className="Overview_Card_Icon">
                    <Icon/>
                </div>
                <div className="Overview_Card_Number">
                    {Number}
                </div>
                <div className="Overview_Card_Text">
                    {Text}
                </div>

            </div>
        </>
    );
};

export default Overview_Card;
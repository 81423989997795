import { useState, useEffect } from "react";
import axios from 'axios';
import HandleFile from "../handlefile";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import AttivitaChatBox from "./attivita_chatbox";
import { HiChevronDown } from "react-icons/hi";
import { HiMiniPencil, HiMiniClipboardDocumentCheck, HiMiniClipboardDocumentList } from "react-icons/hi2";



function AttivitaRealViewer({ navOpened, data, handleChiudi, onSave }) {

    const [isEditing, setIsEditing] = useState(false);
    const [editableTitolo, setEditableTitolo] = useState(data.Titolo);
    const [editableNumeroOfferta, setEditableNumeroOfferta] = useState(data.NumeroOfferta);
    const [editableOrdine, setEditableOrdine] = useState(data.Ordine);
    const [editableCommittente, setEditableCommittente] = useState(data.Committente);
    const [editableCliente, setEditableCliente] = useState(data.Cliente);
    const [editableDescrizione, setEditableDescrizione] = useState(data.Descrizione);
    const [editableOrePreviste, setEditableOrePreviste] = useState(data.OrePreviste);
    const [showSuccessPrendiMessage, setShowSuccessPrendiMessage] = useState(false);
    const [showErrorPrendiMessage, setShowErrorPrendiMessage] = useState(false);
    const [alreadyFiles, setAlreadyFiles] = useState([]);

    const handleSuccessPrendiMessage = () => {
        setShowSuccessPrendiMessage(true);
        setTimeout(() => setShowSuccessPrendiMessage(false), 2000); // Nasconde il messaggio di successo dopo 2 secondi
    };

    const handleErrorPrendiMessage = () => {
        setShowErrorPrendiMessage(true);
        setTimeout(() => setShowErrorPrendiMessage(false), 2000); // Nasconde il messaggio di errore dopo 2 secondi
    };

    const handleModificaClick = () => {
        setIsEditing(!isEditing);
        setOpenNav(false);
    };

    const handleIndietroClick = () => {
        setIsEditing(false);
        setOpenNav(false);
        setEditableTitolo(data.Titolo);
        setEditableNumeroOfferta(data.NumeroOfferta);
        setEditableOrdine(data.Ordine);
        setEditableCommittente(data.Committente);
        setEditableCliente(data.Cliente);
        setEditableDescrizione(data.Descrizione);
    }

    const handleSalvaClick = () => {
        setIsEditing(false);

        const modifiedData = {
            ID: data.ID,
            Titolo: editableTitolo,
            NumeroOfferta: editableNumeroOfferta,
            Ordine: editableOrdine,
            Committente: editableCommittente,
            Cliente: editableCliente,
            Descrizione: editableDescrizione,
            OrePreviste: editableOrePreviste
        };

        axios
            .put('https://www.tonight-torino.com/php/attivita.php', { modifiedData })
            .then((response) => {
                console.log(modifiedData);
                console.log("Risposta dal backend:", response.data);
                onSave();
                handleSuccessPrendiMessage();
            })
            .catch((error) => {
                console.error('Errore nella richiesta AJAX per aggiornamento: ', error);
                handleErrorPrendiMessage();
            });
    };


    const getFilesFromFirebase = async (ticketId) => {
        const storage = getStorage();
        const storageRef = ref(storage, `offerte/${data.NumeroOfferta}/`);

        try {
            const fileList = await listAll(storageRef);

            const filesData = await Promise.all(
                fileList.items.map(async (fileRef) => {
                    const url = await getDownloadURL(fileRef);
                    return { name: fileRef.name, url: url }; // Oggetto con nome e URL del file
                })
            );
            setAlreadyFiles(filesData); // Aggiorna lo stato con gli URL dei file
        } catch (error) {
            console.error(`Errore nel recupero dei file per il ticket ${ticketId}:`, error);
        }
    };

    useEffect(() => {
        getFilesFromFirebase(data.ID);
    }, []);


    const handleCaricaFilesReturn = (files) => {
        setAlreadyFiles(files);
    };

    const handleOnInvia = (OrePlus) => {
        onSave();
        data.Ore = parseInt(data.Ore) + parseInt(OrePlus);
    }

    const handleOnDelete = (OrePlus) => {
        onSave();
        data.Ore = parseInt(data.Ore) - parseInt(OrePlus);
    }

    const [openNav, setOpenNav] = useState(false);
    const handleNav = () => {
        setOpenNav(!openNav);
    }

    const handleNavAttivita = async (State) => {

        const confirm = window.confirm("Sei sicuro di voler concludere l'attività?")
        if(!confirm)
            return;

        setIsEditing(false);

        const modifiedData = {
            ID: data.ID,
            Stato: String(State)
        };
        axios
            .put('https://www.tonight-torino.com/php/attivita.php', { modifiedData })
            .then((response) => {
                console.log("Risposta dal backend:", response.data);
                onSave();
                handleSuccessPrendiMessage();
                setOpenNav(false);
            })
            .catch((error) => {
                console.error('Errore nella richiesta AJAX per aggiornamento: ', error);
                handleErrorPrendiMessage();
            });
    };


    return (
        <>
            <div className={`divApertoTicketMessaggio ${navOpened ? "padding-leftTickets" : ""}`}>
                <div className={`div-ticket-opened`}>
                    <span onClick={handleChiudi} className="chiudiDivxTicket">
                        &times;
                    </span>
                    <div className="div_ticket_nav_button" onClick={handleNav}><HiChevronDown /></div>
                    {openNav &&
                        <div className="div_ticket_nav">
                             <div className="dashboard_dropdown_item" onClick={handleModificaClick}> <HiMiniPencil /> Modifica</div>
                            <div className="dashboard_dropdown_item" onClick={() => handleNavAttivita("Concluso")}> <HiMiniClipboardDocumentCheck /> Concludi</div>
                            <div className="dashboard_dropdown_item" onClick={() => handleNavAttivita("In Corso")}>  <HiMiniClipboardDocumentList /> Riapri</div>
                        </div>
                    }
                    {isEditing ? (
                        <>
                            <div className="div-modifing-div">
                                <div>
                                    <label>Titolo:</label>
                                    <input
                                        className="div-ticket-opened-title width100"
                                        type="text"
                                        value={editableTitolo}
                                        onChange={(e) => setEditableTitolo(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Offerta:</label>
                                    <input
                                        className="div-ticket-opened-title width100"
                                        type="text"
                                        value={editableNumeroOfferta}
                                        onChange={(e) => setEditableNumeroOfferta(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Ordine:</label>
                                    <input
                                        className="div-ticket-opened-title width100"
                                        type="text"
                                        value={editableOrdine}
                                        onChange={(e) => setEditableOrdine(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Committente:</label>
                                    <input
                                        className="div-ticket-opened-title width100"
                                        type="text"
                                        value={editableCommittente}
                                        onChange={(e) => setEditableCommittente(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Cliente:</label>
                                    <input
                                        className="div-ticket-opened-title width100"
                                        type="text"
                                        value={editableCliente}
                                        onChange={(e) => setEditableCliente(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label>Ore Previste:</label>
                                    <input
                                        className="div-ticket-opened-title width100"
                                        type="text"
                                        value={editableOrePreviste}
                                        onChange={(e) => setEditableOrePreviste(e.target.value)}
                                    />
                                </div>
                                {/*<textarea
                                    className="div-ticket-opened-description width100 height50"
                                    value={editableDescrizione}
                                    onChange={(e) => setEditableDescrizione(e.target.value)}
                    />*/}
                            </div>
                            <button onClick={handleSalvaClick} className="div-ticket-toolbar-btnmodify margin-right10">Salva</button>
                            <button onClick={handleIndietroClick} className="div-ticket-toolbar-btnmodify">Indietro</button>
                        </>
                    ) : (
                        <>
                            <div className="div-ticket-opened-title">
                                {/*<button onClick={handleModificaClick} className="div-ticket-modifica-btn">Modifica</button>*/} {editableTitolo}
                            </div>
                            <div className='attivita_info_div'>
                                <div>
                                    <h3>Offerta: {editableNumeroOfferta}</h3>
                                    <h3>Ordine: {editableOrdine}</h3>
                                </div>
                                <div>
                                    <h3>Committente: {editableCommittente}</h3>
                                    <h3>Cliente: {editableCliente}</h3>
                                </div>
                                <div>
                                    <h3>Ore previste: {editableOrePreviste}</h3>
                                    <h3>Ore totali: {data.Ore}</h3>
                                </div>
                            </div>
                            {/*<div className='attivita_descrizione_div'>
                                {editableDescrizione && editableDescrizione !== "null" ? (
                                    decodeURIComponent(editableDescrizione).split('\n').map((line, index) => (
                                        <span key={index}>
                                            {line}
                                            <br />
                                        </span>
                                    ))
                                ) : null}
                            </div>*/}
                            <AttivitaChatBox IDAttivita={data.ID} onInvia={(ore) => handleOnInvia(ore)} onDelete={(ore) => handleOnDelete(ore)} />
                            <div className="attivita_buttons_navigator">
                                <HandleFile onUpload={() => getFilesFromFirebase(data.ID)} isAttivita={true} isAdmin={true} handleCaricaFilesReturn2={onSave} ticketID={data.NumeroOfferta} title="Vedi allegati" VediAllegati={true} AlreadyFiles={alreadyFiles} />
                                <HandleFile onUpload={() => getFilesFromFirebase(data.ID)} isAttivita={true} isAdmin={true} handleCaricaFilesReturn2={onSave} ticketID={data.NumeroOfferta} title="Aggiungi allegati" VediAllegati={false} AlreadyFiles={[]} />
                            </div>
                        </>
                    )}
                </div >
                {showSuccessPrendiMessage && (
                    <div className={`success-message show`}>Successo!</div>
                )
                }
                {
                    showErrorPrendiMessage && (
                        <div className={`error-message show`}>Errore!</div>
                    )
                }
            </div >
        </>
    );
}

export default AttivitaRealViewer;

/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect } from "react";
import ItodoImage from '../../../elements/itodo-img';
import Dashboard_Drawer_Widget from "./dashboard_drawer_widget";
import { HiChartPie, HiCheckCircle, HiChevronDown, HiOutlineUser, HiChartSquareBar, HiClipboard, HiClipboardCheck } from "react-icons/hi";
import { HiMiniDocumentChartBar, HiMiniDocumentCheck, HiMiniFolderPlus  } from "react-icons/hi2";
import { HiLogout } from "react-icons/hi";
import Overview from "../pages/overview";
import Ticket_Crea from "../pages/ticket_crea";
import Ticket_Gestione from "../pages/ticket_gestione";
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import NoPermissionModal from "../../../elements/modals/noPermissionModal";
import Ticket_Archivio from "../pages/ticket_archivio";
import AttivitaPage from "../pages/attivita_page";
import AttivitaArchivio from "../pages/attivita_archivio";
import AttivitaCrea from "../pages/attivita_crea";

function DashBoard_Nav() {
    const [openDashboard, setOpenDashboard] = useState(true);
    const [openAccountIcon, setOpenAccountIcon] = useState(false);
    const [ticketNumber, setTicketsNumber] = useState(0);
    const [archivioTicketNumber, setArchivioTicketsNumber] = useState(0);
    const [isComponentMounted, setIsComponentMounted] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [activeSection, setActiveSection] = useState("overview");
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuthLoaded, setIsAuthLoaded] = useState(false);

    const renderActiveSection = () => {
        switch (activeSection) {
            case "overview":
                return <Overview />
            case "gestione-ticket":
                return <Ticket_Gestione navOpened={openDashboard} />
            case "crea-ticket":
                return <Ticket_Crea navOpened={openDashboard} />
            case "archivio-ticket":
                return <Ticket_Archivio navOpened={openDashboard} />
            case "gestione-attivita":
                return <AttivitaPage navOpened={openDashboard} />
            case "archivio-attivita":
                return <AttivitaArchivio navOpened={openDashboard} />
            case "crea-attivita":
                return <AttivitaCrea navOpened={openDashboard} />
            default:
                return null;
        }
    };

    const fetchTickets = async () => {
        try {
            const response = await fetch('https://www.tonight-torino.com/php/script.php');
            if (response.ok) {
                const data = await response.json();
                if (!isAdmin) {
                    const filteredData = data.filter(ticket => ticket.Autore === "sabelt@auth.com" && (parseInt(ticket.Stato) === 0 || parseInt(ticket.Stato) === 1));
                    setTicketsNumber(filteredData.length);
                    const concludedTickets = data.filter(ticket => ticket.Autore === "sabelt@auth.com" && parseInt(ticket.Stato) === 2);
                    setArchivioTicketsNumber(archivioTicketNumber + concludedTickets.length);
                } else {
                    const filteredData = data.filter(ticket => parseInt(ticket.Stato) === 0 || parseInt(ticket.Stato) === 1);
                    setTicketsNumber(filteredData.length);
                    const concludedTickets = data.filter(ticket => parseInt(ticket.Stato) === 2);
                    setArchivioTicketsNumber(archivioTicketNumber + concludedTickets.length);
                }
            } else {
                console.error('Errore nella richiesta al server:', response.statusText);
            }
        } catch (error) {
            console.error('Errore nella richiesta al server:', error);
        }
    };



    useEffect(() => {
        if (isAuthLoaded) {
            fetchTickets();
        }
    }, [isAuthLoaded, isAdmin]);

    const auth = getAuth();
    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userEmail = user.email;
                setIsAdmin(userEmail.includes('admin@auth.com'));
                setUserEmail(userEmail);
            }
            else {
                setIsAdmin(false);
            }
            setIsAuthLoaded(true);
        });

        return () => unsubscribe();
    }, [auth]);



    const handleLogout = async () => {
        try {
            await signOut(auth);
            if (isComponentMounted) {
            }
            navigate("/login");
        } catch (error) {
            console.error("Errore durante il logout:", error.message);
        }
    };

    const handleExit = async () => {
        try {
            navigate("/");
        } catch (error) {
        }
    };

    const handleAttivitaClick = async () => {
        if (isAdmin) {
            try {
                navigate("/attivita");
            } catch (error) {
            }
        }
        else {
            setShowModal(true);
        }
    }

    return (
        <>
            <header className="dashboard_nav_header">
                <div className="dashboard_nav_intro">
                    <button onClick={() => setOpenDashboard(!openDashboard)} aria-label="Toggle Navigation" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" stroke="currentColor" strokeWidth="2" d="M3 6h18M3 12h18M3 18h18" />
                        </svg>
                    </button>
                    <ItodoImage src="/images/logohead.png"></ItodoImage>
                    <div>Dashboard</div>
                </div>

                <div className="dashboard_nav_end_account">
                    <ItodoImage src={userEmail === "sabelt@auth.com" ? "/images/clienti/sabelt.png" : "/images/Empty.png"}></ItodoImage>
                    <div className="dashboard_nav_end_account_email">
                        {userEmail}
                    </div>
                    <div className="dashboard_nav_end_account_icon" onClick={() => setOpenAccountIcon(!openAccountIcon)}>
                        <HiChevronDown></HiChevronDown>
                    </div>
                </div>
            </header>

            {/* Menu a tendina */}
            {openAccountIcon && (
                <div className="dashboard_dropdown_menu">
                    <div className="dashboard_dropdown_item" onClick={handleLogout}> <HiOutlineUser /> Logout</div>
                    <div className="dashboard_dropdown_item" onClick={handleExit}> <HiLogout /> Esci</div>
                </div>
            )}


            <div className={`dashboard_nav_drawer ${openDashboard ? 'open' : ''}`}>
                <Dashboard_Drawer_Widget Text="Overview" Icon={HiChartPie} onClick={() => setActiveSection("overview")} />
                <Dashboard_Drawer_Widget Text="Gestione Ticket" Icon={HiClipboard} onClick={() => setActiveSection("gestione-ticket")} Number={ticketNumber} />
                <Dashboard_Drawer_Widget Text="Archivio Ticket" Icon={HiClipboardCheck} onClick={() => setActiveSection("archivio-ticket")} Number={archivioTicketNumber} />
                <Dashboard_Drawer_Widget Text="Crea Ticket" Icon={HiCheckCircle} onClick={() => setActiveSection("crea-ticket")} />
                {/*<Dashboard_Drawer_Widget Text="Ticket attesi" Icon={HiInformationCircle} onClick={() => setActiveSection("ticket-completati")} Number="5"/>*/}
                {true && isAdmin && <Dashboard_Drawer_Widget Text="Gestione Attività" Icon={HiMiniDocumentChartBar} onClick={() => setActiveSection("gestione-attivita")} />}
                {true && isAdmin && <Dashboard_Drawer_Widget Text="Archivio Attività" Icon={HiMiniDocumentCheck} onClick={() => setActiveSection("archivio-attivita")} />}
                {true && isAdmin && <Dashboard_Drawer_Widget Text="Crea Attività" Icon={HiMiniFolderPlus} onClick={() => setActiveSection("crea-attivita")} />}
            </div>

            <div className="active-section" key={activeSection}>
                {renderActiveSection()}
            </div>
            {showModal && <NoPermissionModal callBackCloseFunction={() => setShowModal(false)} Desc="Spiacente, non disponi dei permessi necessari." Title="Errore" />}
        </>
    );
}

export default DashBoard_Nav;

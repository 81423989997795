import React, { useState } from "react";
import styled from 'styled-components';
import ItodoImage from '../../elements/itodo-img.jsx';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const LoginTotalPane = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
`;


const PrimoPane = styled.div`
  background-color: white;
  width: 70%;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 460px) {
    width:100%;
  }
`;

const SecondoPane = styled.div`
  background-color: #bdccdb;
  width: 35%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  @media (max-width: 460px) {
    display:none;
  }

`;

const AbsoluteLogo = styled(ItodoImage)`
  position: absolute;
  width: 60%;
  top: 50%; /* Posiziona il centro dell'immagine al 50% dall'alto */
  left: 50%; /* Posiziona il centro dell'immagine al 50% da sinistra */
  transform: translate(-50%, -250%); /* Trasla il centro dell'immagine verso il centro del div */
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  max-width: 100px;

  &:hover {
    background-color: #45a049;
  }
`;

const DivLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-bottom: 40px;
`;

const Bentornato = styled.div`
  font-size: 20px;
`;

const LoginTitle = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: black;
  padding-top: 20px;
  padding-bottom: 40px;
`;

function NewLogin() {


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleErrorMessage = () => {
    setShowErrorMessage(true);
    setTimeout(() => setShowErrorMessage(false), 2000); // Nasconde il messaggio di errore dopo 2 secondi
  };

  const auth = getAuth();
  const navigate = useNavigate();

  function handleLogin(e) {
    e.preventDefault(); // Previene il comportamento di ricaricamento della pagina
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setIsLoggedIn(true);
        if (user.email === 'admin@auth.com') // Verifica l'email correttamente
          navigate('/dashboard');
        else
          navigate('/dashboard');
      })
      .catch((error) => {
        handleErrorMessage();
        console.error(error.message);
      });
  }



  return (
    <>
      <div>
        <LoginTotalPane>
          <div className='perbutton'>
            <NavLink to="/"><button>Indietro</button></NavLink>
          </div>

          <PrimoPane>

            <DivLogin>
              <Bentornato>Bentornato!</Bentornato>
              <LoginTitle>Log In</LoginTitle>
              <LoginForm onSubmit={handleLogin}>
                <Input
                  type="text"
                  placeholder="Email@auth.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
                <Input
                  type="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
                <Button type="submit">Accedi</Button>
              </LoginForm>
            </DivLogin>

          </PrimoPane>
          <SecondoPane>
            <ItodoImage style={{ marginRight: '30%', maxHeight: '77%', width: 'auto' }} src="images/login_image.png" alt="Login image"></ItodoImage>
            <AbsoluteLogo src="images/logo.png" alt="Login image"></AbsoluteLogo>
          </SecondoPane>
          {showErrorMessage && (
            <div className={`error-message show`}>
              Account non trovato o password sbagliata
            </div>
          )}
        </LoginTotalPane>
      </div>
    </>
  );
};

export default NewLogin
import { useState, useEffect } from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import axios from "axios";
import Selecter from "../elements/selecter";
import HandleFile from "../elements/handlefile";
import Dashboard_Loader from "../navigation/dashboard_loader";

function Ticket_Crea({ navOpened }) {


    const firebaseConfig = {
        apiKey: "AIzaSyCtuJ3uDDNlCvz7EXoWyUH6EmOFCBdIh_c",
        authDomain: "scanteq-aaa19.firebaseapp.com",
        projectId: "scanteq-aaa19",
        storageBucket: "scanteq-aaa19.appspot.com",
        messagingSenderId: "330344662556",
        appId: "1:330344662556:web:caff0b5787f90c563ec1d9",
        measurementId: "G-RW2056K68F"
    };
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);

    const [email, setEmail] = useState(null);
    const auth = getAuth();
    const [nome, setNome] = useState('');
    const [formemail, setFormEmail] = useState('');
    const [formtitle, setFormTitle] = useState('');
    const [cellulare, setCellulare] = useState('');
    const [messaggio, setMessaggio] = useState('');
    const [priorita, setPriorita] = useState('');
    const [tipoSWHW, setTipoSWHW] = useState('');

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        if (auth.currentUser) {
            const userEmail = auth.currentUser.email;
            setEmail(userEmail);
        }
    }, [auth]);

    const autore = `${email}`;

    const [emailData, setEmailData] = useState({
        to: 'cosmin.stoica@scanteq.com',
        subject: `E STATO APERTO UN NUOVO TICKET`,
    });

    const [emailDataValentin, setEmailDataValentin] = useState({
        to: 'valentin.drogoreanu@scanteq.com',
        subject: `E STATO APERTO UN NUOVO TICKET`,
    });


    const custom = 'Sc38ahD38sW31Hshue';
    const sendEmail = async () => {
        try {
            const response = await axios.post('https://www.tonight-torino.com/php/send-email.php', emailData,
                {
                    headers: {
                        'Custom-Header': custom,
                    },
                });
        } catch (error) {
            console.error('Errore nell\'invio dell\'e-mail:', error);
        }
        try {
            const responseValentin = await axios.post('https://www.tonight-torino.com/php/send-email.php', emailDataValentin,
                {
                    headers: {
                        'Custom-Header': custom,
                    },
                });
        } catch (error) {
            console.error('Errore nell\'invio dell\'e-mail:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const conferma = window.confirm("Sei sicuro di voler creare nuovo ticket?");

        if (!conferma) {
            return;
        }

        const data = {
            Nome: nome,
            Messaggio: encodeURIComponent(messaggio),
            Autore: autore,
            Email: formemail,
            Titolo: formtitle,
            Tipo: tipoSWHW,
            Priorita: priorita === "Normale" ? 0 : priorita === "Importante" ? 1 : priorita === "Urgente" ? 2 : 0,
            Cellulare: encodeURIComponent(cellulare),
            Stato: '0'
        };

        const updatedEmailData = {
            ...emailData,
            text: `Nuovo ticket da ${autore} \n Nome: ${nome} \n Titolo: ${formtitle}`,
        };

        const updatedEmailDataValentin = {
            ...emailDataValentin,
            text: `Nuovo ticket da ${autore} \n Nome: ${nome} \n Titolo: ${formtitle}`,
        };

        if (email !== "admin@auth.com") {
            setEmailData({ ...updatedEmailData });
            setEmailDataValentin(updatedEmailDataValentin);
            sendEmail();
        }

        try {
            const jsonData = JSON.stringify(data);
        
            const response = await fetch('https://www.tonight-torino.com/php/script.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: jsonData,
            });
        
            const responseData = await response.json(); // Prima risposta
            console.log('Risposta grezza dal server:', responseData);
        
            if (response.ok) {
                const ticketId = responseData.ticketId;
                console.log('ticket id:', ticketId);
                if (uploadedFiles.length > 0) {
                    await uploadFilesToFirebase(uploadedFiles, ticketId);
                }

                const response = await axios.post("https://www.tonight-torino.com/php/comments_ticket.php", {
                    Nome: nome,
                    Messaggio: encodeURIComponent(messaggio),
                    Autore: autore,
                    IDTicket: ticketId
                });
        
                console.log(response.data); 
        
                handleSuccessMessage();
                setNome("");
                setFormEmail("");
                setCellulare("");
                setMessaggio("");
                setFormTitle("");
            } else {
                console.log(responseData);
                console.log(responseData.message);
                handleErrorMessage();
                console.error('Errore nella richiesta al server:', response.statusText);
            }
        } catch (error) {
            handleErrorMessage();
            console.error('Errore nella richiesta al server:', error);
        }        
    };

    const handleSuccessMessage = () => {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 2000); // Nasconde il messaggio di successo dopo 2 secondi
    };

    const handleErrorMessage = () => {
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 2000); // Nasconde il messaggio di errore dopo 2 secondi
    };


    const handleNomeChange = (e) => {
        setNome(e.target.value);
    };


    const handleFormEmailChange = (e) => {
        setFormEmail(e.target.value);
    };

    const handleFormTitleChange = (e) => {
        setFormTitle(e.target.value);
    };

    const handleCellulareChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Rimuove tutti i caratteri non numerici

        // Aggiorna il valore dell'input con solo caratteri numerici
        setCellulare(numericValue);
    };
    const handleMessaggioChange = (e) => {
        setMessaggio(e.target.value);
    };


    const Choices1 = [
        {
            icon: '❗️',
            description: 'Urgente'
        },
        {
            icon: '⚠️',
            description: 'Importante'
        },
        {
            icon: '✅',
            description: 'Normale'
        }
    ];

    const Choices2 = [
        {
            icon: '💻',
            description: 'Software'
        },
        {
            icon: '🔧',
            description: 'Hardware'
        }
    ];



    const [uploadedFiles, setUploadedFiles] = useState([]);


    const handleCaricaFilesReturn = (files) => {
        setUploadedFiles(files);
        console.log('Files caricati:', files);
    };

    const uploadFilesToFirebase = async (files, ticketId) => {
        const storage = getStorage();
        setShowLoader(true);
        for (let file of files) {
            const storageRef = ref(storage, `ticket/${ticketId}/${file.name}`);
            try {
                await uploadBytes(storageRef, file);
                console.log(`File ${file.name} caricato con successo.`);
            } catch (error) {
                console.error(`Errore nel caricamento del file ${file.name}:`, error);
            }
        }
        setUploadedFiles([]);
        setShowLoader(false);
    };


    return (
        <>
            <div className={`CreaTicket_MainDiv ${navOpened ? "padding-leftTickets" : ""}`}>
                <form class="formToCreateTicket " onSubmit={handleSubmit}>
                    <p class="titleToCreateTicket">Crea Ticket </p>
                    <div className="formToCreateTicketUpbar">
                        <p class="messageToCreateTicket">Inserisci i dati e crea un nuovo ticket </p>
                        <HandleFile AlreadyFiles={uploadedFiles} handleCaricaFilesReturn2={handleCaricaFilesReturn} />
                    </div>
                    <div className='formToCreateTicket-div_upper height100'>
                        <div className='formToCreateTicket-div '>
                            <Selecter Status="Priorità" Choices={Choices1} setSelection={setPriorita}></Selecter>
                            <Selecter Status="Tipo" Choices={Choices2} setSelection={setTipoSWHW}></Selecter>
                            <label>
                                <input class="inputToCreateTicket" type="text" placeholder="" required value={nome}
                                    onChange={handleNomeChange} />
                                <span>Nome</span>
                            </label>

                            <label>
                                <input class="inputToCreateTicket" type="email" placeholder="" required value={formemail}
                                    onChange={handleFormEmailChange} />
                                <span>Email</span>
                            </label>
                            <label>
                                <input

                                    value={cellulare}
                                    onChange={handleCellulareChange}
                                    class="inputToCreateTicket"
                                    type="phone"
                                    placeholder=""
                                    required
                                    inputmode="numeric"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                />
                                <span>Numero di telefono</span>
                            </label>
                            <label>
                                <input class="inputToCreateTicket" type="text" placeholder="" required value={formtitle}
                                    onChange={handleFormTitleChange} maxLength="48" />
                                <span>Titolo</span>
                            </label>
                        </div>

                        <div className='formTicket-secondPart'>
                            <label>
                                <textarea
                                    value={messaggio}
                                    onChange={handleMessaggioChange}
                                    class="inputToCreateTicket maxinputToCreateTicket"
                                    placeholder=""
                                    required
                                    rows="4"
                                ></textarea>
                                <span>Messaggio</span>
                            </label>
                        </div>
                    </div>
                    <button class="submitToCreateTicket" >Invia</button>
                </form>
            </div>
            <div className='creaticketdivnuovo'>
                {showSuccessMessage && (
                    <div className={`success-message show`}>
                        Richiesta inviata con successo
                    </div>
                )}
                {showErrorMessage && (
                    <div className={`error-message show`}>
                        Richiesta non inviata con successo
                    </div>
                )}
            </div>
            {showLoader && <Dashboard_Loader />}
        </>
    );
};

export default Ticket_Crea;
function Dashboard_Drawer_Widget({ Text, Icon, onClick, Number }) {
    return (
        <>
            <div onClick={onClick} className="dashboard_drawer_widget">
                {Icon &&
                    <div className="dashboard_drawer_widget_icon">
                        <Icon />
                    </div>
                }
                <div className="dashboard_drawer_widget_description">
                    {Text}
                </div>
                {Number &&
                    <div className="dashboard_drawer_widget_number">
                        {Number}
                    </div>
                }
            </div>
        </>
    );
};

export default Dashboard_Drawer_Widget;
import { BiLinkExternal } from "react-icons/bi";
import { useState, useEffect, useMemo } from "react";
import Dashboard_Loader from "../../navigation/dashboard_loader";
import axios from 'axios';
import Selecter from "../selecter";
import AttivitaRealViewer from "./attivita_RealViewer";
import { FcApproval,FcHighPriority  } from "react-icons/fc";

function TableAttivita({ navOpened, requireDataHandler, data, Choices1Data, Choices1Text}) {
    const [showLoader, setShowLoader] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [orderByTimestamp, setOrderByTimestamp] = useState(true);
    const [filterPriority, setFilterPriority] = useState('');

    const handleBLinkClick = (datatoSend) => {
        setShowLoader(true);
        setSelectedData(datatoSend);
        setTimeout(() => {
            setShowLoader(false);  
        }, 500);
    }

    const filteredData = useMemo(() => {
        return data && Array.isArray(data)
            ? data.filter(item => filterPriority ? item.Committente === filterPriority : true)
            : [];
    }, [data, filterPriority]);

    return (
        <>
            <div className="Table_Toolbar">
                <form className="formSearchTicket">
                    <button>
                        <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                            <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </button>
                    <input
                        className="inputSearchTicket"
                        placeholder="Cerca nelle attività"
                        required=""
                        type="text"
                    />
                    <button className="resetSearchTicket" type="reset">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </form>
                <div>Filtra per</div>
                <Selecter
                    Status={Choices1Text}
                    Choices={Choices1Data}
                    setSelection={setFilterPriority}
                />
            </div>
            <div className="Table_CounterTicket">
                {filteredData ? filteredData.length : '0'} Attività trovate
            </div>
            <table className="Ticket_Table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Descrizione</th>
                        <th>Offerta</th>
                        <th>Cliente</th>
                        <th>Committente</th>
                        <th>Ordine</th>
                        <th>Stato</th>
                        <th>Ore Prev</th>
                        <th>Ore Tot</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((data, index) => (
                        <tr key={index}>
                            <td className="text-center">
                                <div className={parseInt(data.Stato) === 2 ? `bg-green color-white` : ""}>
                                    {index+1} 
                                    {data.Stato === "Concluso"  && <FcApproval/>}
                                    {parseInt(data.OrePreviste) < parseInt(data.Ore) && <FcHighPriority />}
                                </div>
                            </td>
                            <td>
                                <div className="perflexnoncentratonomargin">
                                    <div className="ticket_icon_title">
                                        <BiLinkExternal onClick={() => handleBLinkClick(data)} />
                                    </div>
                                    {data.Titolo}
                                </div>
                            </td>
                            <td>{data.NumeroOfferta}</td>
                            <td>{data.Cliente}</td>
                            <td>{data.Committente}</td>
                            <td>{data.Ordine}</td>
                            <td>{data.Stato}</td>
                            <td>{data.OrePreviste}</td>
                            <td>{data.Ore}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {showLoader && <Dashboard_Loader />}
            {true && selectedData && <AttivitaRealViewer onSave={() => requireDataHandler()} navOpened={navOpened} data={selectedData} handleChiudi={() => setSelectedData(null)}/>}

        </>
    );
};

export default TableAttivita;

import Table from "../elements/table";
import axios from "axios";
import { useState, useEffect } from "react";
import Dashboard_Loader from "../navigation/dashboard_loader";
import { getAuth, onAuthStateChanged } from 'firebase/auth'

function Ticket_Gestione({ navOpened }) {
    const [showLoader, setShowLoader] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const auth = getAuth();
    useEffect(() => {
        if (auth.currentUser) {
            const userEmail = auth.currentUser.email;
            setIsAdmin(userEmail.includes('admin@auth.com'));
        }
    }, [auth]);

    const Choices1 = [
        {
            icon: '❗️',
            description: 'Urgente'
        },
        {
            icon: '⚠️',
            description: 'Importante'
        },
        {
            icon: '✅',
            description: 'Normale'
        }
    ];

    const Choices2 = [
        {
            icon: '💻',
            description: 'Software'
        },
        {
            icon: '🔧',
            description: 'Hardware'
        }
    ];

    const Choices3 = [
        {
            icon: '⏺',
            description: 'Ricevuto'
        },
        {
            icon: '🔁',
            description: 'In Corso'
        },
        {
            icon: '⏹',
            description: 'Concluso'
        }
    ];


    const [tickets, setTickets] = useState([]);
    const fetchTickets = async () => {
        setShowLoader(true);
        try {
            const response = await fetch('https://www.tonight-torino.com/php/script.php');
            if (response.ok) {
                const data = await response.json();
                const newData = data.filter(ticket => parseInt(ticket.Stato) === 0 || parseInt(ticket.Stato) === 1);
                setTickets(newData);
            } else {
                console.error('Errore nella richiesta al server:', response.statusText);
            }
        } catch (error) {
            console.error('Errore nella richiesta al server:', error);
        } finally {

            setTimeout(() => {
                setShowLoader(false);  // Nascondi il loader dopo il ritardo
            }, 800);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    return (
        <>
            <div className={`Ticket_InCorso_MainDiv ${navOpened ? "padding-leftTickets" : ""}`}>
                {true && <Table navOpened={navOpened} requireDataHandler={() => fetchTickets()} isAdmin={isAdmin} GeneralData={tickets} Choices1Data={Choices1} Choices1Text="Priorità" Choices2Data={Choices2} Choices2Text="Tipo" Choices3Data={Choices3} Choices3Text="Stato" />}
            </div>
            {showLoader && <Dashboard_Loader />}
        </>
    );
};

export default Ticket_Gestione;
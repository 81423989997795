import { useState } from 'react';
import { HiOutlineDownload } from "react-icons/hi";
import { getStorage, ref, deleteObject, getDownloadURL, uploadBytes } from "firebase/storage";

function FileModal({ isAttivita, isAdmin, handleClose, handleCaricaFilesReturn, AlreadyFiles, VediAllegati, ticketID, onUpload }) {
    const [files, setFiles] = useState(AlreadyFiles); // Stato che mantiene i file caricati
    const [dragging, setDragging] = useState(false); // Stato per il feedback visivo


    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const handleFileUpload = (event) => {
        const uploadedFiles = event.target.files;
        processFiles(uploadedFiles);
    };
    // Gestisce i file trascinati
    const handleFileDrop = (event) => {
        event.preventDefault();
        setDragging(false); // Rimuove il feedback visivo
        const droppedFiles = event.dataTransfer.files;
        processFiles(droppedFiles);
    };

    // Funzione per gestire i file e controllare la dimensione
    const processFiles = (fileList) => {
        const validFiles = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].size <= 5 * 1024 * 1024) { // Limite di 5 MB
                validFiles.push(fileList[i]);
            } else {
                alert(`${fileList[i].name} supera il limite di 5 MB e non sarà caricato.`);
                handleErrorMessage();
            }
        }
        handleSuccessMessage();
        setFiles(prevFiles => [...prevFiles, ...validFiles]);
        handleCaricaFilesReturn(prevFiles => [...prevFiles, ...validFiles]);
    };




    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true); // Attiva il feedback visivo durante il drag
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragging(false); // Rimuove il feedback visivo
    };

    const handleCaricaFiles = (event) => {
        if (event) {
            event.preventDefault();
        }
        handleClose();
    };


    const handleSuccessMessage = () => {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 2000); // Nasconde il messaggio di successo dopo 2 secondi
    };

    const handleErrorMessage = () => {
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 2000); // Nasconde il messaggio di errore dopo 2 secondi
    };

    const handleDeleteFile = (event, indexToDelete) => {
        event.preventDefault();
        setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
        handleCaricaFilesReturn((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
    };


    const handleDeleteFileFirebase = async (event, indexToDelete) => {
        event.preventDefault();

        const fileToDelete = files[indexToDelete];
        const storage = getStorage();
        let fileRef;

        if (isAttivita) {
            fileRef = ref(storage, `offerte/${ticketID}/${fileToDelete.name}`);
        } else {
            fileRef = ref(storage, `ticket/${ticketID}/${fileToDelete.name}`);
        }

        try {
            await deleteObject(fileRef);
            console.log(`File ${fileToDelete.name} eliminato con successo.`);
            if(isAttivita)
                onUpload();
            setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
            handleCaricaFilesReturn((prevFiles) => prevFiles.filter((_, index) => index !== indexToDelete));
        } catch (error) {
            console.error(`Errore nell'eliminazione del file ${fileToDelete.name}:`, error);
            handleErrorMessage();
        }
    };
    const handleDownloadFileFirebase = async (event, indexToDownload) => {
        event.preventDefault();

        const fileToDownload = files[indexToDownload];
        const fileName = fileToDownload.name ? fileToDownload.name : fileToDownload.split('/').pop().split('?')[0];

        if (!ticketID) {
            console.error('ticketID non è definito.');
            return;
        }

        const storage = getStorage();
        let fileRef;

        if (isAttivita) {
            fileRef = ref(storage, `offerte/${ticketID}/${fileName}`);
        } else {
            fileRef = ref(storage, `ticket/${ticketID}/${fileName}`);
        }


        try {
            const url = await getDownloadURL(fileRef);

            // Crea un link per aprire il file in una nuova scheda
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.target = '_blank';  // Apre il file in una nuova scheda
            a.rel = 'noopener noreferrer';  // Aggiunge sicurezza per evitare potenziali rischi di sicurezza

            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);

            console.log(`File ${fileName} aperto in una nuova scheda con successo.`);
        } catch (error) {
            console.error(`Errore nell'apertura del file ${fileName}:`, error);
            handleErrorMessage();
        }
    };

    const handleAggiungiAllegatiAttivita = async () => {
        if (!files || files.length === 0) {
            window.alert("Nessun file selezionato per il caricamento.");
            return;
        }
        const storage = getStorage();
        for (let file of files) {
            const storageRef = ref(storage, `offerte/${ticketID}/${file.name}`);
            try {
                await uploadBytes(storageRef, file);
                handleSuccessMessage();
                onUpload();
            } catch (error) {
                console.error(`Errore nel caricamento del file ${file.name}:`, error);
                handleErrorMessage();
            }
        }
    }

    return (
        <>
            <div className='divApertoTicket'>
                <div className="FileModalMainDiv">
                    <span onClick={handleClose} className='chiudiDivxTicket'>
                        &times;
                    </span>

                    {!VediAllegati && <div
                        className={`containerFileTicket ${dragging ? 'dragging' : ''}`}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        <div className="folderFileTicket">
                            <div className="topFileTicket"></div>
                            <div className="bottomFileTicket"></div>
                        </div>
                        <label className="custom-file-uploadFileTicket">
                            <input className="titleFileTicket" type="file" multiple onChange={handleFileUpload} />
                            Scegli un file o trascinalo qui
                        </label>
                    </div>}
                    {/*isAttivita && <div
                        className={`containerFileTicket ${dragging ? 'dragging' : ''}`}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        <div className="folderFileTicket">
                            <div className="topFileTicket"></div>
                            <div className="bottomFileTicket"></div>
                        </div>
                        <label className="custom-file-uploadFileTicket">
                            <input className="titleFileTicket" type="file" multiple onChange={handleFileUpload} />
                            Scegli un file o trascinalo qui
                        </label>
                    </div>*/}
                    {/*<button className='fileModalBtn' onClick={handleClose}>Carica File</button>*/}
                    {isAttivita && !VediAllegati && <div className='width100 perflex'><button className='button-sm' onClick={handleAggiungiAllegatiAttivita} >Aggiungi allegati</button></div>}
                    {!VediAllegati && <div className={`uploadedFilesList`}>
                        <ul>
                            {files.length > 0 ? (
                                files.map((file, index) => (
                                    <>
                                        <div className='uploadedFilesListDiv'>
                                            <li key={index}>{file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB</li>
                                            <button className='uploadedFilesListDiv_btnDelete' onClick={(event) => handleDeleteFile(event, index)}>X</button>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <p>Nessun file caricato.</p>
                            )}
                        </ul>
                    </div>}
                    {VediAllegati && <>
                        <div className={`perflex width100 height100  /*isAttivita ? "isAttivitaList" : ""*/`}>
                            <ul className='uploadedFilesList-UL'>
                                {files.length > 0 ? (
                                    files.map((file, index) => {
                                        const fileName = file.name ? file.name : file.split('/').pop(); // Estrae il nome del file dall'URL se `file.name` non esiste
                                        return (
                                            <div className='uploadedFilesListDiv' key={index}>
                                                <li>
                                                    {fileName}
                                                    {file.size ? ` - ${(file.size / 1024 / 1024).toFixed(2)} MB` : ''}
                                                </li>
                                                {isAdmin && <button className='uploadedFilesListDiv_btnDelete' onClick={(event) => handleDeleteFileFirebase(event, index)}>X</button>}
                                                <button className='uploadedFilesListDiv_btnDownload' onClick={(event) => handleDownloadFileFirebase(event, index)}><HiOutlineDownload /></button>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p>Nessun file caricato.</p>
                                )}
                            </ul>
                        </div>
                    </>}
                    {showSuccessMessage && (
                        <div className={`success-message show`}>
                            File caricato con successo
                        </div>
                    )}
                    {showErrorMessage && (
                        <div className={`error-message show`}>
                            Errore nel caricamento dei file
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default FileModal;

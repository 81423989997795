import React, { useEffect, useState } from "react";
import axios from 'axios';
import TicketChatMessage from "./ticket_chatmessage";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Dashboard_Loader from "../navigation/dashboard_loader";
import TicketLoading from "./ticket_loading";

function TicketChatBox({ IDTicket, onInvia }) {
    const [messages, setMessages] = useState([]);
    const [userEmail, setUserEmail] = useState("");
    const [showLoader, setShowLoader] = useState(false);


    const auth = getAuth();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userEmail = user.email;
                setUserEmail(userEmail);
            }
        });
        return () => unsubscribe();
    }, [auth]);

    const fetchComments = async () => {
        setShowLoader(true);
        try {
            const response = await fetch(`https://www.tonight-torino.com/php/comments_ticket.php?IDTicket=${IDTicket}`);
            if (!response.ok) {
                throw new Error('Errore nella risposta della rete');
            }

            const text = await response.text();
            const data = JSON.parse(text);
            setMessages(data);
        } catch (error) {
            console.error("Errore nel caricamento dei messaggi:", error);
        } finally {
            setTimeout(() => {
                setShowLoader(false);
            }, 800);
        }
    };

    useEffect(() => {
        fetchComments();
    }, [IDTicket]);



    const [nome, setNome] = useState("");
    const [messaggio, setMessaggio] = useState("");

    const handleSendMessage = async (e) => {
        e.preventDefault();


        if (!nome.trim() || !messaggio.trim()) {
            window.alert("Per favore, compila sia il nome che il messaggio.");
            return;
        }

        const isConfirmed = window.confirm("Sei sicuro di voler inviare questo commento?");
        if (!isConfirmed) return;

        try {
            const response = await axios.post("https://www.tonight-torino.com/php/comments_ticket.php", {
                IDTicket,
                Nome: nome,
                Messaggio: messaggio,
                Autore: userEmail
            });
            fetchComments()
            setNome("")
            setMessaggio("")
            onInvia();
        } catch (error) {
            console.error("Errore nell'invio del commento:", error);
        }
    };



    return (
        <>
            <div className="ticket_chatbox_upper">
                <div className="ticket_chatbox_messages">
                    {Array.isArray(messages) && messages.map((msg, index) => {
                        let decodedMessage;
                        try {
                            decodedMessage = decodeURIComponent(msg.Messaggio);
                        } catch (e) {
                            console.error("Errore nel decodificare il messaggio:", e);
                            decodedMessage = msg.Messaggio; // Usa il messaggio originale in caso di errore
                        }
                        return (
                            <>
                                {showLoader && <TicketLoading />}
                                {!showLoader && <TicketChatMessage
                                    key={index}
                                    Name={msg.Nome}
                                    Date={msg.Data}
                                    Message={decodedMessage}
                                    isScanteq={msg.Autore === "admin@auth.com"}
                                    MessageID={msg.ID}
                                    onRefresh={fetchComments}
                                    isAdmin={userEmail === "admin@auth.com"}
                                    canDelete={index !== 0}
                                />}
                            </>
                        );
                    })}
                </div>
                <div className="ticket_chatbox_toolbar">
                    <div className="ticket_message-input">
                        <form>
                            <textarea
                                placeholder="Inserisci il tuo commento qui"
                                className="ticket_message-send"
                                value={messaggio}
                                onChange={(e) => setMessaggio(e.target.value)}
                            />
                            <textarea
                                placeholder="Nome Cognome"
                                className="ticket_message-send-namesurname"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                            <button onClick={handleSendMessage} className="ticket_button-send">Invia</button>
                        </form>
                    </div>
                </div>
            </div>
            {false && <Dashboard_Loader />}
        </>
    );
}

export default TicketChatBox;

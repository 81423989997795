import { Title } from "chart.js";
import React, { useEffect, useState } from "react";

function TicketPrompt({ ticketData }) {
  const { Data, Autore, Email, Nome, Cell, Messaggio, Titolo } = ticketData;

  const decodedMessaggio = decodeURIComponent(Messaggio);

  // Inizializza lo stato con il valore iniziale di Stato
  const [stato, setStato] = useState("");
  const soloData = Data.split(" ")[0];

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");

  const formatEmailAsDate = (date) => {
    const dateParts = date.split("-");
    if (dateParts.length === 3) {
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    }
    return date;
  };
  const formattedDate = formatEmailAsDate(soloData);

  useEffect(() => {
    // Aggiorna lo stato solo se il valore di ticketData.Stato è diverso
    if (ticketData.Stato == 0) {
      setStato("In corso");
    } else if (ticketData.Stato == 1) {
      setStato("Concluso");
    }
  }, [ticketData.Stato]);

  const ticketClass =
    stato === "In corso" ? "blue-backgroundss" : "green-background";

  const handleVediDipiu = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <>
      <div class="cardForTicket">
        <div class="headerForTicket">
          {
            <div class="imageForTicket">
              {stato === "Concluso" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                  <g
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    id="SVGRepo_tracerCarrier"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="1.5"
                      stroke="#000000"
                      d="M20 7L9.00004 18L3.99994 13"
                    ></path>{" "}
                  </g>
                </svg>
              ) : (
                <svg
                  width="100%"
                  height="100"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 10"
                >
                  <line
                    x1="0"
                    y1="5"
                    x2="100"
                    y2="5"
                    stroke="#FFD700"
                    stroke-width="10"
                  />
                </svg>
              )}
            </div>
          }
          <div class="contentForTicket">
            <span class="realTitleForTicket">{Titolo}</span>
            <br></br>
            <span class="titleForTicket">{formattedDate}</span>
            <br></br>
            {/*<p class="messageForTicket ticketMessageMax">
           {decodedMessaggio}
  </p>*/}
          </div>
          <span>Email:</span>
          <p class="messageForTicket">{Email}</p>
          <span>Nome:</span>
          <p class="messageForTicket">{Nome}</p>
          <button className="button-vediticket vediTicketForAdmin" onClick={handleVediDipiu}>
            Vedi di più
          </button>
          {/*<span>Cell:</span>
          <p class="messageForTicket">
           {Cell}
</p>*/}
        </div>
      </div>

      {modalVisible && (
        <>
          <div className="divApertoTicketMessaggio">
            <div className="div-ticket-opened">
              <span onClick={handleVediDipiu} className="chiudiDivxTicket">
                &times;
              </span>
              <div className="div-ticket-opened-title">{Titolo}</div>
              <div className="div-ticket-opened-message">
                {decodedMessaggio}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TicketPrompt;

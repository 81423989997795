import Selecter from "./selecter";
import { BiLinkExternal } from "react-icons/bi";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import TicketViewer from "./ticket_viewer";
import { useState, useEffect, useMemo } from "react";
import Dashboard_Loader from "../navigation/dashboard_loader";
import { ImWrench } from "react-icons/im";

function Table({ navOpened, requireDataHandler, GeneralData, Choices1Data, Choices1Text, Choices2Data, Choices2Text, Choices3Data, Choices3Text, isAdmin }) {


    const [filterPriority, setFilterPriority] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [searchText, setSearchText] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const [orderClickedStatus, setOrderClickedStatus] = useState(false);
    const [orderClickedPriority, setOrderClickedPriority] = useState(false);
    const [orderClickedDate, setOrderClickedDate] = useState(false);

    const [selectedData, setSelectedData] = useState(null);
    const handleBLinkClick = (datatoSend) => {
        setShowLoader(true);
        setSelectedData(datatoSend);
        setTimeout(() => {
            setShowLoader(false);  // Nascondi il loader dopo il ritardo
        }, 500);
    }

    const onDelete = () => {
        setSelectedData(null);
        requireDataHandler();
    }

    const priorityMap = {
        'Normale': 0,
        'Importante': 1,
        'Urgente': 2,
    };
    const statusMap = {
        'Ricevuto': 0,
        'In Corso': 1,
        'Concluso': 2,
    };


    const [filteredData, setFilteredData] = useState([]);
    const mappedPriority = useMemo(() => priorityMap[filterPriority], [filterPriority, priorityMap]);
    const mappedStatus = useMemo(() => statusMap[filterStatus], [filterStatus, statusMap]);

    useEffect(() => {
        const updatedFilteredData = GeneralData.filter((data) => {
            if (!isAdmin) {
                return (
                    (filterPriority === '' || Number(data.Priorita) === Number(mappedPriority)) &&
                    (filterType === '' || data.Tipo === filterType) &&
                    (filterStatus === '' || Number(data.Stato) === Number(mappedStatus)) &&
                    (searchText === '' || data.Titolo.toLowerCase().includes(searchText.toLowerCase())) &&
                    data.Autore === "sabelt@auth.com"
                );
            } else {
                return (
                    (filterPriority === '' || Number(data.Priorita) === Number(mappedPriority)) &&
                    (filterType === '' || data.Tipo === filterType) &&
                    (filterStatus === '' || Number(data.Stato) === Number(mappedStatus)) &&
                    (searchText === '' || data.Titolo.toLowerCase().includes(searchText.toLowerCase()))
                );
            }
        });

        setFilteredData(updatedFilteredData);
    }, [filterPriority, filterType, filterStatus, searchText, isAdmin, GeneralData, mappedPriority, mappedStatus]);

    const handleOrderStatus = () => {
        const newOrderClicked = !orderClickedStatus;
        setOrderClickedStatus(newOrderClicked);

        const orderedData = [...filteredData].sort((a, b) => {
            if (!newOrderClicked) {
                return (a.Stato > b.Stato) ? 1 : -1;
            } else {
                return (a.Stato < b.Stato) ? 1 : -1;
            }
        });

        setFilteredData(orderedData);
    };

    const handleOrderPriority = () => {
        const newOrderClicked = !orderClickedPriority;
        setOrderClickedPriority(newOrderClicked);

        const orderedData = [...filteredData].sort((a, b) => {
            const priorityA = Number(a.Priorita);
            const priorityB = Number(b.Priorita);

            if (!newOrderClicked) {
                return priorityA - priorityB;
            } else {
                return priorityB - priorityA;
            }
        });

        setFilteredData(orderedData);
    };

    const handleOrderDate = () => {
        const newOrderClicked = !orderClickedDate;
        setOrderClickedDate(newOrderClicked);

        const orderedData = [...filteredData].sort((a, b) => {
            const dateA = new Date(a.Data);
            const dateB = new Date(b.Data);

            return newOrderClicked ? dateB - dateA : dateA - dateB;
        });

        setFilteredData(orderedData);
    };
    console.log(filteredData);


    return (
        <>
            <div className="Table_Toolbar">
                <form class="formSearchTicket">
                    <button>
                        <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                            <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </button>
                    <input
                        className="inputSearchTicket"
                        placeholder="Cerca nei ticket"
                        required=""
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <button class="resetSearchTicket" type="reset">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </form>
                <div>Filtra per</div>
                {Choices1Data &&
                    <Selecter
                        Status={Choices1Text}
                        Choices={Choices1Data}
                        setSelection={setFilterPriority}
                    />}
                {Choices2Data &&
                    <Selecter
                        Status={Choices2Text}
                        Choices={Choices2Data}
                        setSelection={setFilterType}
                    />}
                {Choices3Data &&
                    <Selecter
                        Status={Choices3Text}
                        Choices={Choices3Data}
                        setSelection={setFilterStatus}
                    />}
            </div>
            <div className="Table_CounterTicket">
                {filteredData ? filteredData.length : '0'} Ticket trovati
            </div>
            <div className="Ticket_Table_Container">
                <table className="Ticket_Table">
                    <tr>
                        <th></th>
                        <th>Titolo</th>
                        <th>Offerta</th>
                        <th>Ordine</th>
                        <th>Autore</th>
                        <th>
                            Priorità
                            {!orderClickedPriority && <HiChevronDown className="Ticket_orderStatusBtn" onClick={handleOrderPriority} />}
                            {orderClickedPriority && <HiChevronUp className="Ticket_orderStatusBtn" onClick={handleOrderPriority} />}
                        </th>
                        <th>Tipo</th>
                        <th>
                            Data
                            {!orderClickedDate && <HiChevronDown className="Ticket_orderStatusBtn" onClick={handleOrderDate} />}
                            {orderClickedDate && <HiChevronUp className="Ticket_orderStatusBtn" onClick={handleOrderDate} />}
                        </th>
                        <th>
                            Stato
                            {!orderClickedStatus && <HiChevronDown className="Ticket_orderStatusBtn" onClick={handleOrderStatus} />}
                            {orderClickedStatus && <HiChevronUp className="Ticket_orderStatusBtn" onClick={handleOrderStatus} />}
                        </th>
                    </tr>
                    {filteredData && Array.isArray(filteredData) && filteredData.map((data, index) => (
                        <tr>
                            <td className="text-center">
                                <div className={parseInt(data.Stato) === 2 ? `bg-green color-white` : ""}>
                                    {parseInt(data.Manutenzione) === 1 ? <div><ImWrench /></div> : index+1 }
                                </div>
                            </td>
                            <td>
                                <div className="perflexnoncentratonomargin">
                                    <div className="ticket_icon_title">
                                        <BiLinkExternal onClick={() => handleBLinkClick(data)} />
                                    </div>
                                    {data.Titolo}
                                </div>
                            </td>
                            <td>
                                {data.Offerta}
                            </td>
                            <td>
                                {data.Ordine}
                            </td>
                            <td>
                                {data.Nome}
                            </td>
                            <td>
                                <div className="perflexnoncentratonomargin">
                                    <div className={`ticket_status_circle ${parseInt(data.Priorita) === 0 ? 'blue' : parseInt(data.Priorita) === 1 ? 'yellow' : parseInt(data.Priorita) === 2 ? 'red' : ''}`}>
                                    </div>
                                    {parseInt(data.Priorita) === 0 ? "Normale" : parseInt(data.Priorita) === 1 ? "Importante" : parseInt(data.Priorita) === 2 ? "Urgente" : ""}
                                </div>

                            </td>
                            <td>
                                <div className="perflexnoncentratonomargin">
                                    <div className="ticket_icon_title">
                                        {data.Tipo === "Software" ? '💻' : data.Tipo === "Hardware" ? '🔧' : ' '}
                                    </div>
                                    {data.Tipo}
                                </div>
                            </td>
                            <td>{data.Data}</td>
                            <td>
                                {parseInt(data.Stato) === 0 ? "Ricevuto" : parseInt(data.Stato) === 1 ? "In corso" : parseInt(data.Stato) === 2 ? "Concluso" : ""}

                            </td>
                        </tr>
                    ))}
                </table>
            </div>
            {showLoader && <Dashboard_Loader />}
            {selectedData && <TicketViewer navOpened={navOpened} data={selectedData} handleChiudi={() => setSelectedData(null)} onTicketDelete={onDelete} onTicketConcludi={() => requireDataHandler()} />}
        </>
    );
};

export default Table;
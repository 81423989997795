import React, { useState } from 'react';
import axios from 'axios';

function InserisciOffertaOrdine({ data, handleClose, handleSuccess, handleError }) {
    const [offerta, setOfferta] = useState(data.Offerta);
    const [ordine, setOrdine] = useState(data.Ordine);

    const handleSave = async () => {
        const conferma = window.confirm("Sei sicuro di voler salvare l'offerta e l'ordine?");
        if (conferma) {
            try {
                const response = await axios.put('https://www.tonight-torino.com/php/script.php', {
                    ticketId: data.ID,
                    Offerta: offerta,
                    Ordine: ordine
                });
                if (response.status === 200) {
                    handleSuccess();
                } else {
                    handleError();
                }
                handleClose();
            } catch (error) {
                console.error('Errore durante la richiesta:', error);
                handleError();
            }
        }
    };

    return (
        <>
            <div className='divApertoTicket'>
                <div className="OffertaModalMainDiv">
                    <span onClick={handleClose} className='chiudiDivxTicket'>
                        &times;
                    </span>
                    <div>
                        <label>
                            Offerta:
                            <input
                                type="text"
                                value={offerta}
                                onChange={(e) => setOfferta(e.target.value)}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            Ordine:
                            <input
                                type="text"
                                value={ordine}
                                onChange={(e) => setOrdine(e.target.value)}
                            />
                        </label>
                    </div>
                    <button onClick={handleSave}>Save</button>
                </div>
            </div>
        </>
    );
};

export default InserisciOffertaOrdine;

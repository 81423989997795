import Overview_Card from "./overview_card";
import { HiInformationCircle, HiCheckCircle, HiDotsCircleHorizontal } from "react-icons/hi";
import { Pie } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import Dashboard_Loader from "../navigation/dashboard_loader";
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import TicketViewer from "../elements/ticket_viewer";

function Overview() {
    const [ticketNumberCompletati, setTicketsNumberCompletati] = useState(0);
    const [ticketNumberCorso, setTicketNumberCorso] = useState(0);
    const [ticketNumberRicevuti, setTicketNumberRicevuti] = useState(0);
    const [showLoader, setShowLoader] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuthLoaded, setIsAuthLoaded] = useState(false); 

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userEmail = user.email;
                setIsAdmin(userEmail.includes('admin@auth.com'));
            } else {
                setIsAdmin(false);
            }
            setIsAuthLoaded(true); 
        });

        return () => unsubscribe();
    }, [auth]);

    const data = {
        labels: ['Completati', 'In corso', 'Ricevuti'],
        datasets: [
            {
                data: [ticketNumberCompletati, ticketNumberCorso, ticketNumberRicevuti],
                backgroundColor: ['rgba(50, 168, 82, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(75, 192, 192, 0.6)'],
                borderColor: 'rgba(255, 255, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    const fetchTickets = async () => {
        try {
            setShowLoader(true);
            const response = await fetch('https://www.tonight-torino.com/php/script.php');
            if (response.ok) {
                const tickets = await response.json();

                let completati = 0;
                let inCorso = 0;
                let ricevuti = 0;

                if (!isAdmin) {
                    tickets.forEach(ticket => {
                        if (parseInt(ticket.Stato) === 0 && ticket.Autore === "sabelt@auth.com") {
                            ricevuti += 1;
                        } else if (parseInt(ticket.Stato) === 1 && ticket.Autore === "sabelt@auth.com") {
                            inCorso += 1;
                        } else if (parseInt(ticket.Stato) === 2 && ticket.Autore === "sabelt@auth.com") {
                            completati += 1;
                        }
                    });
                } else {
                    tickets.forEach(ticket => {
                        if (parseInt(ticket.Stato) === 0) {
                            ricevuti += 1;
                        } else if (parseInt(ticket.Stato) === 1) {
                            inCorso += 1;
                        } else if (parseInt(ticket.Stato) === 2) {
                            completati += 1;
                        }
                    });
                }
                setTicketsNumberCompletati(completati);
                setTicketNumberCorso(inCorso);
                setTicketNumberRicevuti(ricevuti);
            } else {
                console.error('Errore nella richiesta al server:', response.statusText);
            }
        } catch (error) {
            console.error('Errore nella richiesta al server:', error);
        } finally {
            setTimeout(() => {
                setShowLoader(false);
            }, 800);
        }
    };

    useEffect(() => {
        if (isAuthLoaded) {
            fetchTickets();
        }
    }, [isAuthLoaded, isAdmin]);

    const dataTicket = {
        Titolo: "Titolo di esempio",
        Messaggio: encodeURIComponent("Questo è un messaggio di esempio."),
        ID: 123,
        Email: "esempio@dominio.com",
        Data: new Date().toISOString(),
        Nome: "Nome Esempio"
    };
    

    return (
        <>
            <div className="Overview_MainDiv">

                <div className="Overview_MainDiv_ForCards">

                    {false && <TicketViewer data={dataTicket}/>}

                    <Overview_Card Icon={HiCheckCircle} Number={ticketNumberCompletati} Text="Ticket completati"></Overview_Card>
                    <Overview_Card Icon={HiDotsCircleHorizontal} Number={ticketNumberCorso} Text="Ticket in corso"></Overview_Card>
                    <Overview_Card Icon={HiInformationCircle} Number={ticketNumberRicevuti} Text="Ticket ricevuti"></Overview_Card>
                </div>

                <div>
                    <Pie data={data} />
                </div>
            </div>
            {showLoader && <Dashboard_Loader />}
        </>
    );
};

export default Overview;
